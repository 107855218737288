.header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-left: 20px;
    margin-top: 60px;
    z-index: 10;
    border-left: 2px solid #b8d3e6;
}

.name {
    font-family: 'New Amsterdam', serif;
    /* font-size: 85px; */
    font-size: 55px;
    /* font-weight: 900; */
    letter-spacing: 8px;
    /* color: #b8d3e6; */
    color: #ffffff;
    line-height: 1.2;
}
.smallName {
    font-size: 40px;

}

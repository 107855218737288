/* src/components/contact.css */

/* General Styles */
.bg-tertiary {
  background-color: #2c2c2c;
}

.text-white {
  color: #fff;
}

.text-secondary {
  color: #a1a1a1;
}

.bg-black-100 {
  background-color: #1a1a1a;
}

.green-pink-gradient {
  background: linear-gradient(90deg, #ff6ec4 0%, #7873f5 100%);
}

.rounded-lg {
  border-radius: 0.5rem;
}

.shadow-md {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.shadow-primary {
  box-shadow: 0 4px 6px rgba(255, 105, 180, 0.3);
}

.font-medium {
  font-weight: 500;
}

.font-bold {
  font-weight: 700;
}

.text-center {
  text-align: center;
}

/* Modal Styles */
.fixed {
  position: fixed;
}

.inset-0 {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.bg-opacity-50 {
  background-color: rgba(0, 0, 0, 0.5);
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.bg-white {
  background-color: white;
}

.p-6 {
  padding: 1.5rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.rounded-lg {
  border-radius: 1rem;
}

.text-red-500 {
  color: #f56565;
}

.text-green-500 {
  color: #48bb78;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.bg-blue-500 {
  background-color: #4299e1;
}

.mt-12 {
  margin-top: 3rem;
}

.py-3 {
  padding: 0.75rem;
}

.px-8 {
  padding: 2rem;
}

.w-fit {
  width: fit-content;
}

.w-10 {
  width: 2.5rem;
}

.h-10 {
  height: 2.5rem;
}

.h-8 {
  height: 2rem;
}

.w-8 {
  width: 2rem;
}

.hover\\:scale-110:hover {
  transform: scale(1.1);
}

.cursor-pointer {
  cursor: pointer;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

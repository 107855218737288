.about-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* height: auto; */
    color: #352c2c; /* White text */
    font-family: 'New Amsterdam', serif;
    text-align: left;
    padding: 20px;
    height: 100vh;
    margin-top: 20px; /* Add margin for spacing */
    width: 100%;
}

.about-container h1 {
    font-size: 105px;
    font-weight: bold;
    margin-bottom: 20px;
    letter-spacing: 8px;
    padding-left: 35px;
}

.about-container p {
    font-size: 30px;
    line-height: 1.5;
    font-family: 'Roboto', serif;
    font-weight: 400;
    letter-spacing: 2px;
    padding-left: 90px;
    padding-right: 90px;
    /* margin-right: 50px; */
}

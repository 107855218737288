/* src/components/SplineGlobe.css */

.spline-container {
    width: 100%;
    height: 100vh; /* Makes the globe full height */
    display: flex;
    justify-content: center;
    background-color: #3C3838;
    align-items: center;
    border-radius: 15px;
  }
  
body, html {
  margin: 0;
  padding: 0;
  font-family: 'Source Serif 4', serif;
  /* background-color: #004245; */
  background-color: #000d16;
  /* background-color: #000d16;  */
  height: 100%;
  width: 100%;
}

spline-viewer {
  position: fixed; /* or absolute */
  top: 0;
  left: 0;
  width: 100vw; /* Full width */
  height: 100vh; /* Full height */
  z-index: 999; /* Higher than other components */
  pointer-events: none; /* Prevent interactions with the Spline viewer if you want to click through */
}
.app {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  min-height: 100vh; /* Full viewport height */
  overflow: hidden;
}

/* Header styling */
.header {
  flex: 0 1 auto;
}

/* Header adjustments */
.header {
  position: absolute;
  top: 20px;
  left: 20px;
}

.header h1 {
  font-size: 65px;
  font-weight: bold;
}

/* GLTFViewer should take up a portion of the screen */
.gltf-viewer-container {
  flex: 1 1 auto; /* Let GLTFViewer grow */
  height: 80vh; /* Adjust height as needed */
  width: 100%;
}

/* About section */
.about-container {
  width: 100%;
  flex: 0 1 auto; /* Let About take up content height */
  background-color: #d8d8d8; /* Dark background */
  padding-top: 50px;
  padding-left: 20px;
  padding-right: 20px;

}
.experience-container {
  width: 100%;
  flex: 0 1 auto; /* Let About take up content height */
  background-color: #d8d8d8; /* Dark background */


}

.project-container {
  width: 100%;
  flex: 0 1 auto; /* Let About take up content height */
  background-color: #000d16;

  /* padding: 50px 20px; */
}

.contact-container {
  width: 100%;
  flex: 0 1 auto; /* Let About take up content height */
  background-color: #000d16;
  font-family: 'Poppins', sans-serif;

  /* padding: 50px 20px; */
}

.footer-container {
  width: 100%;
  flex: 0 1 auto; /* Let About take up content height */
  /* background-color: #d8d8d8; */
  background-color: #000d16;

  /* padding: 50px 20px; */
}

/* Footer remains at the bottom */
.footer {
  flex: 0 1 auto;
}



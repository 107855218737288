.project-container {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    height: auto; /* Allow this to be based on content */
    /* color: #352c2c;  */
    color: #d7d6dd; 
    text-align: left;
    padding: 20px;
    padding-top: 0px;
    /* padding-left: 90px; */
    /* height: 100vh; */
    font-family: 'Roboto', serif;
    width: 100%;
    margin: 0;
}

h2 {
    font-size: 75px;
    font-weight: 200;
    font-family: 'Roboto', serif;
    margin-top: 40px;
    letter-spacing: 5px;
    text-align: left;
    /* padding-left: 90px; */
}



